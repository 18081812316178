import { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';

import ProtectAuth from './pages/auth/protect-auth';
import MyContainer from './components/public-container/Container';
import Layout from './components/user/layout/layout';
import Protected from './pages/protect-route/protected';
import ProtectAdmin from './pages/protect-route/ProtectAdmin';
const LoginPage = lazy(() => import('./pages/auth/login'));
const RegisterPage = lazy(() => import('./pages/auth/signup'));
const HalamanPendaftaran = lazy(() => import('./pages/daftar/daftar'));
const UserPage = lazy(() => import('./pages/user/dashboard'));
const AdminDashboardPage = lazy(() => import('./pages/admin/dashboard'));
// const SppCekPage = lazy(()=>import('./pages/user/spp-cek'));
const MuridPage = lazy(() => import('./pages/admin/murid'));
const DaftarWali = lazy(() => import('./pages/admin/wali'));

// import SppPage from './pages/admin/keuangan/spp';
// import BayarSppPage from './pages/admin/keuangan/transaksi/spp';
// import CekSppPage from './pages/admin/keuangan/cek-spp';
const HomePage = lazy(() => import('./pages/home'));
const PpdbPage = lazy(() => import('./pages/admin/ppdb'));
const UnitPage = lazy(() => import('./pages/admin/unit'));
const FaqPage = lazy(() => import('./pages/admin/faq'));
const CarouselPage = lazy(() => import('./pages/admin/carousel'));
const ProfilePage = lazy(() => import('./pages/user/setting/profile'));
const ChangePasswordPage = lazy(() =>
  import('./pages/user/setting/change-password')
);
const FormPesertaDidikPage = lazy(() => import('./pages/user/form-data'));
// const TestPage = lazy(()=>import('./pages/TestPage'));
// const MuridDtPage = lazy(() => import('./pages/admin/murid/muridDt'));
// import ReportVaPage from './pages/admin/keuangan/spp/ReportVa';

function App() {
  return (
    <Routes>
      <Route element={<MyContainer />}>
        <Route
          path="/"
          element={
            <Suspense>
              <HomePage />
            </Suspense>
          }
        />
        <Route
          path="daftar/:unit/:id"
          element={
            <Suspense>
              <HalamanPendaftaran />
            </Suspense>
          }
        />
      </Route>

      <Route element={<ProtectAuth />}>
        <Route
          path="auth/login"
          element={
            <Suspense>
              <LoginPage />
            </Suspense>
          }
        />
        <Route
          path="auth/signup"
          element={
            <Suspense>
              <RegisterPage />
            </Suspense>
          }
        />
        {/* <Route path="testva" element={<TestPage />} /> */}
      </Route>

      <Route element={<Protected />}>
        <Route element={<Layout />}>
          <Route
            path="user/dashboard"
            element={
              <Suspense>
                <UserPage />
              </Suspense>
            }
          />
          {/* <Route path="user/spp-cek" element={<SppCekPage />} /> */}
          <Route
            path="user/setting/profile"
            element={
              <Suspense>
                <ProfilePage />
              </Suspense>
            }
          />
          <Route
            path="user/setting/change-password"
            element={
              <Suspense>
                <ChangePasswordPage />
              </Suspense>
            }
          />
          <Route
            path="user/form-data/:siswaId/:tgl/:tmp/:nama/:judul"
            element={
              <Suspense>
                <FormPesertaDidikPage />
              </Suspense>
            }
          />
          <Route element={<ProtectAdmin />}>
            <Route
              path="admin/dashboard"
              element={
                <Suspense>
                  <AdminDashboardPage />
                </Suspense>
              }
            />
            <Route
              path="admin/murid"
              element={
                <Suspense>
                  <MuridPage />
                </Suspense>
              }
            />
            <Route
              path="admin/wali"
              element={
                <Suspense>
                  <DaftarWali />
                </Suspense>
              }
            />
            <Route
              path="admin/ppdb"
              element={
                <Suspense>
                  <PpdbPage />
                </Suspense>
              }
            />
            <Route
              path="admin/unit"
              element={
                <Suspense>
                  <UnitPage />
                </Suspense>
              }
            />
            <Route
              path="admin/faq"
              element={
                <Suspense>
                  <FaqPage />
                </Suspense>
              }
            />
            <Route
              path="admin/carousel"
              element={
                <Suspense>
                  <CarouselPage />
                </Suspense>
              }
            />
          </Route>

          {/* <Route path="admin/keuangan/spp" element={<SppPage />} />
          <Route
            path="admin/keuangan/transaksi/spp"
            element={<BayarSppPage />}
          />
          <Route path="admin/keuangan/cek-spp" element={<CekSppPage />} />
          <Route path="admin/keuangan/report" element={<ReportVaPage />} /> */}

          {/* <Route
            path="admin/testdt"
            element={
              <Suspense>
                <MuridDtPage />
              </Suspense>
            }
          /> */}
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
